import React, { useState, Component } from 'react'
import descript from '../helpers/data.jsx'
import Slider from "react-slick";

class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      pauseOnHover: false,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 6000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <div className="constrictcarousel">
        <Slider {...settings}>
        {descript.map((data, local,e) => {
      return <div className="img-carousel" role="button" key={local} alt={local}>
              <img src={ data.image }/> 
            </div>
            
    })}
        </Slider>
      </div>
    );
  }
}


const Carousel = ({index, guardarIndex}) => {
//  const {count , guardarCount} = useState(0);
  // const handleClick = (e) =>{
  //   guardarIndex(e);
  // }

  return (
    <div>
    <div className="carousel-container">
    {/* {descript.map((data, local,e) => {
      if(index !== local || index === count)
      return <div className="img-carousel" role="button" key={local} alt={local} onKeyPress={e=> handleClick(local)} onClick={e =>  handleClick(local)}>
              <img src={ data.image }/> 
              <div className="carousel-text" role="button" key={local} alt={local} onKeyPress={e=> handleClick(local)} onClick={e =>  handleClick(local)}>
                <div className="carousel-name">{ data.name }</div>
                <div className="carousel-price">${ data.price }</div>
              </div>
            </div>
            
    })} */}
    <SimpleSlider/>

  </div>
    <div className="constrictcarousel">
    </div>
    </div>
  )
}

export default Carousel